import request from './index'

export function reviewUsersID (params) {
  return request({
    url: '/api/user/reals/',
    method: 'get',
    params
  })
}

export function reviewUserID (name, data) {
  return request({
    url: `/api/user/reals/${name}/review`,
    method: 'post',
    data
  })
}

export function userIDInfo (name) {
  return request({
    url: `/api/user/reals/${name}`,
    method: 'get'
  })
}
