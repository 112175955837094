<template>
  <div id=main>
    <Header/>
    <b-container>
      <el-tabs type="border-card">
        <el-tab-pane>
        <span slot="label">身份审核</span>
        </el-tab-pane>
        <p>用户名：{{userDatas.username}}</p>
        <p>真实姓名：{{userDatas.realname}}</p>
        <p>认证类型：{{userDatas.realType}}</p>
        <p>证件号码：{{userDatas.idCardNum}}</p>
        <div v-if="rtype === 'company'">
          <p>统一社会信用代码：{{userDatas.socailCredit}}</p>
          <p>开户行账号：{{userDatas.opBankAccount}}</p>
        </div>
        <div class="g-viewer-canvas">
          <viewer class="viewer" :images="userDatas.images">
            <img v-for="src in userDatas.images" :key="src" :src="src">
          </viewer>
        </div>
        <div class="btn-group-bbottom">
          <el-button type="danger" @click="showFlag = true">拒绝</el-button>
          <el-button type="success" @click="acceptDatas">通过</el-button>
        </div>
      </el-tabs>
    </b-container>
    <el-dialog
    title="提示"
    :visible.sync="showFlag"
    width="30%"
    :before-close="handleClose">
    <span>请输入拒绝理由!</span>
    <br>
    <br>
    <br>
    <el-input placeholder="请输入拒绝理由" v-model="reason"></el-input>
    <span slot="footer" class="dialog-footer">
    <el-button @click="calSure">取 消</el-button>
    <el-button type="primary"  @click="rejectDatas">确 定</el-button>
    </span>
    </el-dialog>
    <Footer/>
 </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer/src/component.vue'
import { reviewUserID, userIDInfo } from '@/api/review'

export default {
  name: 'app',
  components: {
    Header,
    Footer,
    Viewer
  },
  data () {
    return {
      username: '',
      userDatas: {},
      rtype: '',
      options: {
        inline: true,
        button: true,
        navbar: false,
        title: false,
        toolbar: true,
        tooltip: false,
        movable: true,
        zoomable: true,
        rotatable: true,
        scalable: false,
        transition: false,
        fullscreen: false,
        keyboard: false,
        url: 'data-source'
      },
      showFlag: false,
      reason: ''
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      const vm = this
      // var url = new URL(document.location)
      // vm.username = url.searchParams.get('username')
      vm.username = this.$route.query.username
      // vm.username = 'ke_666'
      vm.getDatas()
    })
  },
  methods: {
    getDatas () {
      const vm = this
      userIDInfo(vm.username).then(
        res => {
          const { data } = res
          vm.rtype = data.realType
          // 成功
          let realType = '身份证'
          let userPhoto = []
          switch (data.realType) {
            case 'idcard':
              realType = '身份证'
              userPhoto = [data.idCardFront, data.idCardBack]
              break
            case 'passport':
              realType = '护照'
              userPhoto = [data.passportFront]
              break
            case 'company':
              realType = '企业认证'
              userPhoto = [data.idCardFront, data.idCardBack, data.bsnssLicense, data.opBankPermit]
              break
            default:
              realType = '未知'
          }
          vm.userDatas = {
            username: data.username,
            realname: data.realName,
            realType: realType,
            idCardNum: data.idCardNum || data.passportNum,
            socailCredit: data.socailCredit,
            opBankAccount: data.opBankAccount,
            images: userPhoto
          }
        }
      ).catch(
        err => {
          console.log(err)
        }
      )
    },
    acceptDatas () {
      const vm = this
      vm.reviewInfo(2, 1) // 通过type1第二个参数
    },
    rejectDatas () {
      const vm = this
      // this.showFlag = true
      vm.reviewInfo(3, 2) // 拒绝type2第二个参数
    },
    reviewInfo (status, type) {
      if (type === 1) {
        // 通过
        const vm = this
        reviewUserID(vm.username, {
          realStatus: status
        // reason: '默认通过'
        }).then(res => {
        // console.log(res)
          if (res.code === 0) {
            vm.$notify.success({
              title: '成功',
              message: res.message,
              duration: 2000
            })
          }
        })
      } else {
        // 不通过
        if (this.reason.trim() !== '') {
          const vm = this
          reviewUserID(vm.username, {
            realStatus: status,
            reason: this.reason
          }).then(res => {
            if (res.code === 0) {
              vm.$notify.success({
                title: '成功',
                message: res.message,
                duration: 2000
              })
              //  拒绝成功框消失
              this.reason = ''
              this.showFlag = false
            }
          })
        // console.log('有内容')
        } else {
          this.$notify.error({ title: '错误', message: '拒绝理由不能为空!' })
        }
      }
      // let vm = this
      // reviewUserID(vm.username, {
      //   realStatus: status
      // }).then(
      //   res => {
      //     vm.$notify.success({
      //       title: '成功',
      //       message: res.message,
      //       duration: 2000
      //     })
      //   }
      // ).catch(
      //   err => {
      //     console.log(err)
      //   }
      // )
    },
    calSure () {
      this.reason = ''
      this.showFlag = false
    }
  }
}
</script>

<style>
/* footer 固定在页面底部 */

b-container {
  width: 80%;
  margin: 20px auto;
}

b-container .el-tabs--border-card {
  -webkit-box-shadow: 0 0 0 #fff;
  box-shadow: 0 0 0 #fff;
}

b-container .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  font-weight: bold;
  background: transparent;
  border: 0 !important;
}

b-container .el-tabs--border-card > .el-tabs__content {
  margin: 22px auto 0;
}

b-container .el-table {
  margin-bottom: 20px;
}

b-container .el-form-item__error {
  padding: 0;
}

@media (max-width: 768px) {
  b-container {
    width: 90%;
    margin: 20px auto;
  }
  b-container .el-tabs--border-card > .el-tabs__content {
    margin: 22px auto 0;
  }
}

@media (min-width: 1200px) {
  b-container {
    width: 70%;
    margin: 20px auto;
  }
  b-container .el-tabs--border-card > .el-tabs__content {
    width: 80%;
    margin: 22px auto 0;
  }
  b-container .el-input__inner {
    max-width: 300px;
  }
}

#main {
  display: flex;
  flex-direction: column;
}

.viewer > img {
 width: 30%;
 margin: 10px 1%;
}
.btn-group-bbottom {
  margin-top:20px;
  margin-bottom:20px;
  display: flex;
  justify-content:flex-end;
}
.g-viewer-canvas {
  width: 100%;
  height: 550px;
  position: relative
}
.g-viewer-canvas .viewer {
  height: 550px;
  overflow: hidden;
}
</style>
